<template>
    <div>
        <label>{{ $t(labelPrefix + 'new_value') }}</label>
        <multiselect v-model="inputVal"
                     name="new_value"
                     :searchable="true"
                     :multiple="true"
                     :options="getCustomerTypes()"
                     select-label=""
        ></multiselect>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getCustomerTypes']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>